import React, { useState } from "react";

const Accordion = (props) => {
  const [item, setItem] = useState(props.datas);

  const handleToggleActive = () => {
    let newActive = item.active === 1 ? 0 : 1;
    setItem({ ...item, active: newActive });
  };

  return (
    <div
      className={`bg-none p-4 border border-[#292929] mb-5  rounded-xl w-[360px] md:w-[580px] duration-700 group ${
        item.active === 1 ? "is-active bg-white" : ""
      }`}
    >
      <div className="flex items-center">
        <div className="w-full duration-500 text-md group-[.is-active]:font-bold">
          {item.question}
        </div>
        <div
          className="text-[25px] duration-500 rotate-90 group-[.is-active]:rotate-[180deg] cursor-pointer"
          onClick={handleToggleActive}
        >
          +
        </div>
      </div>
      <div className="overflow-hidden mt-3 duration-500 text-lg max-h-0 group-[.is-active]:max-h-[350px]">
        {item.answer}
      </div>
    </div>
  );
};

export default Accordion;
