import React from "react";

// ASSETS
import logo from "../assets/logo-white.png";

import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

function Header({ nav, setNav }) {
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="w-full min-h-[50px] border-b-2 flex md:justify-around justify-between  items-center bg-[#115D33] ">
      <div className="flex items-center justify-center">
        <img
          className="w-[200px] sm:w-[250px] h-[100px] m-auto"
          src={logo}
          alt="logo sistema"
        />
      </div>
      <ul className="hidden sm:flex px-4">
        <li className="py-2 mx-4 my-6 md:my-0">
          <a className="text-xl text-white font-semibold" href="#section1">
            Home
          </a>
        </li>
        <li className=" py-2 mx-4 my-6 md:my-0">
          <a className="text-xl text-white font-semibold" href="#section2">
            Serviços
          </a>
        </li>
        <li className="py-2 mx-4 my-6 md:my-0">
          <a className="text-xl text-white font-semibold" href="#section3">
            Quem Somos
          </a>
        </li>
        <li className="py-2 mx-4 my-6 md:my-0">
          <a className="text-xl text-white font-semibold" href="#section4">
            Perguntas Frequentes
          </a>
        </li>
      </ul>
      <div>
        <div onClick={handleNav} className="sm:hidden z-10 mr-5 cursor-pointer">
          {!nav ? (
            <FaBars size={30} color="#FAFAFA" />
          ) : (
            <AiOutlineClose size={20} color="#292929" />
          )}
        </div>
      </div>
      <div
        onClick={handleNav}
        className={
          nav
            ? "md:hidden ease-in duration-500 absolute text-gray-800 left-0 top-0 w-full h-full bg-[#115D33] px-4 py-7 flex flex-col "
            : "absolute top-0 h-full left-[-150%] ease-in duration-500 "
        }
      >
        <ul className="h-full w-full text-center pt-12">
          <li className="text-3xl py-5 font-bold border-b-2 text-white border-gray-500 ">
            <a href="#section1">Home</a>
          </li>
          <li className="text-3xl py-5 font-bold border-b-2 text-white border-gray-500">
            <a href="#section2">Serviços</a>
          </li>
          <li className="text-3xl py-5 font-bold border-b-2 text-white border-gray-500 ">
            <a href="#section3">Quem Somos</a>
          </li>
          <li className="text-3xl py-5 font-bold border-b-2 text-white border-gray-500">
            <a href="#section4">Perguntas Frequentes</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
