import React from "react";

import logo from "../assets/icon-whatsapp.svg";

import { IoArrowUpSharp } from "react-icons/io5";

//COMPONENTS
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Footer from "./Footer";

function ContentLandingPage() {
  function ScrollTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
      <a
        href="https://wa.me/5506692059271?text=Olá, Conheci a Empresa pelo site e gostaria de obter mais informações."
        target="blank"
      >
        <img
          src={logo}
          alt="Logo do WhatsApp"
          className="fixed bottom-6 right-[5.5rem] w-[65px] cursor-pointer "
        />
      </a>

      <button
        onClick={ScrollTop}
        className="fixed bottom-8 right-8 bg-[#292929] border-[#292929] hover:bg-gray-800 duration-500 text-white px-2 py-2 rounded-full"
      >
        <IoArrowUpSharp size={30} className="border-none" />
      </button>
    </div>
  );
}

export default ContentLandingPage;
