import Main from './pages/LandingPage'

function App() {
  return (
    <div>
     <Main />
    </div>
  );
}

export default App;
