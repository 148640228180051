import React from "react";

import { motion } from "framer-motion";
import { fadeIn } from "../animation";

import logo from "../assets/logo-white.png";

import { FaWhatsapp } from "react-icons/fa";

function Section1() {
  return (
    <div
      className="w-full h-[500px] flex flex-col sm:flex-row  font-[Poppins] p-4 animated-background bg-gradient-to-r from-[#115D33]  to-[#228B22]"
      id="section1"
    >
      <motion.div
        variants={fadeIn("right", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.5 }}
        className="flex flex-col w-[80%] sm:w-[60%] mt-4 px-6 items-center justify-center sm:justify-between "
      >
        <div className="ml-16 ">
          <h1 className="text-[60px] font-bold flex items-center sm:items-start text-white mt-10">
            DEVOOLVE SOLUÇÕES
          </h1>
          <p className="text-white flex items-start text-lg mb-3">
            Atuamos na devolução de cobrança indevida (venda casada) por parte
            das instituições financeiras com foco em seguros
          </p>
          <a
            href="https://wa.me/55062995528649?text=Olá, Conheci a Empresa pelo site e gostaria de obter mais informações."
            target="blank"
          >
            <button className="flex w-full max-w-[350px] mt-6 mb-6  items-center justify-center border p-3 bg-green-500 text-white font-semibold text-lg rounded-3xl cursor-pointer">
              <FaWhatsapp className="mr-2 text-2xl" />
              Fale com um Especialista
            </button>
          </a>
        </div>
      </motion.div>
      <motion.div
        variants={fadeIn("left", 0.1)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="hidden sm:flex flex-col w-[30%] m-auto justify-center items-center"
      >
        <img src={logo} alt="Logo  Devolve Solucoes" />
      </motion.div>
    </div>
  );
}

export default Section1;
