import React from "react";

// ICONS
import { BsFillHouseGearFill } from "react-icons/bs";
import { BsTelephoneFill } from "react-icons/bs";
import { FaFacebook, FaMapMarkerAlt } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

// ASSETS
import logo from "../assets/logo-white.png";


function Footer() {
  return (
    <div className="w-full bg-[#115D33]">
      <div>
        <img
          className="w-[300px] h-[100px] m-auto"
          src={logo}
          alt="logo sistema"
        />
      </div>
      <div className="flex flex-col md:flex-row items-center justify-around">
        <div className="w-[50%] h-[200px] mb-5 sm:mb-0">
          <p className="text-center text-white text-xl font-semibold">
            Nossos Dados
          </p>
          <div className="flex items-center justify-center">
            <ul className="flex flex-col text-white items-start justify-center  mt-3">
              <a target="_blank" href='https://www.google.com/maps/place/Devoolve/@-16.7424593,-49.2769135,15z/data=!4m6!3m5!1s0x6e1388e03983f20f:0x836a8dec3b9a2056!8m2!3d-16.7424593!4d-49.2769135!16s%2Fg%2F11y4l0w4p7?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D'>
              <li className="text-start flex">
                <FaMapMarkerAlt  className="mr-2 text-2xl" />
                <p>Av. Rio Verde QD 97 L. 04/04 Sala 2112, Edificio E-Business, Vila São Tomaz, Aparecida de Goiânia - GO</p>
              </li>
              </a>
              <li className="text-start flex mt-2">
                <BsTelephoneFill size={20} className="mr-2" />
                <p>+55 (62) 98183-3339</p>
              </li>
              <li className="text-start flex mt-2">
                <MdEmail size={20} className="mr-2" /> <p>devoolve@gmail.com</p>
              </li>
              <li className="text-start flex mt-2 mb-4 sm:mb-0">
                <BsFillHouseGearFill size={20} className="mr-2" />{" "}
                <div>
                  <p>Devolve Soluções Financeiras LTDA.</p>
                  <p>54.151.327/0001-93</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-[50%] mt-20 sm:mt-0 h-[200px]">
          <p className="text-center text-white text-xl font-semibold">
            Siga nos nas Nossas Redes Sociais
          </p>
          <div className="flex items-center justify-center mt-2">
            <a
              href="https://www.facebook.com/profile.php?id=61559152421940"
              target="_blank"
              className="gap-4"
            >
              <FaFacebook size={30} className="text-white" />
            </a>
            <a
              href="https://www.instagram.com/devoolve/"
              target="_blank"
              className="gap-4"
            >
              <FaInstagram size={30} className="mx-2 text-white" />
            </a>
          </div>
        </div>
      </div>
      <div>
        <p className="text-[#BABABA] text-center border-t-2 border-gray-200 p-3 mt-10 ">
          &copy; 2024 Devoole Soluções Financeiras - Todos os direitos
          reservados.
        </p>
        <p className="text-[#BABABA] text-center mt-3">
          Desenvolvido por Aslin Tecnologia
        </p>
      </div>
    </div>
  );
}

export default Footer;
