import React, { useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../animation";

// COMPONENTS
import Accordion from "./Accordion";

//IMAGE
import imageFaq from "../assets/imagefaq.jpg.png";

function Section4() {
  const [list, setList] = useState([
    {
      question:
        "O resgate do seguro gera alguma restrição com o banco ou vai me impedir de solicitar novos empréstimos?",
      answer:
        "Não, o resgate do seguro não gera restrição com o banco, pois o resgate é direito do cliente e poderá ser solicitado a qualquer momento. A respeito da solicitação de novos empréstimos, quanto a solicitação e resgate do seguro não tem nada que influencie na não aprovação de empréstimos futuros.",
    },
    {
      question:
        "Qual o valor do seguro e quanto tenho para solicitar o resgate?",
      answer:
        "Na maioria das vezes o valor do seguro é calculado em uma determinada porcentagem do valor total do empréstimo, esse valor vai diminuindo conforme o empréstimo vai sendo quitado, logo, após finalizar o pagamento do empréstimo, o valor fica zerado, não podendo solicitar mais o resgate.",
    },
    {
      question: "Quais produtos podem ter seguro?",
      answer:
        "Empréstimo pessoal, Empréstimo consignado, Empréstimo empresarial e financiamento veicular são os principais produtos que atuamos.",
    },
    {
      question: "Como saber se eu tenho seguro a ser resgatado ou não?",
      answer:
        "Cada caso tem que ser analisado de forma singular, pois cada contrato é individual, logo, muda de um para o outro. Se você possui algum empréstimo ativo atualmente e está na dúvida se tem algum valor de seguro que pode se resgatado, fale com um de nossos consultores, desta forma o seu caso será analisado de forma individual e assim direcionado da melhor maneira.",
    },
    {
      question:
        "Caso eu solicite o resgate do meu seguro, posso receber em quanto tempo?",
      answer:
        "Essa questão vai variar de banco para banco, pois cada banco tem sua forma de operar nos procedimentos, tem banco que paga em 72hrs úteis, contudo, tem bancos que demoram quatro semanas.",
    },
  ]);

  return (
    <div
      id="section4"
      className=" font-[Poppins] text-[#292929] bg-green-50 p-4"
    >
      <h1 className="text-center font-semibold text-3xl ">
        Duvidas Frequentes
      </h1>

      <div className="w-full h-full flex-col sm:flex md:flex-row justify-center sm:justify-around items-center">
        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
        >
          <img
            src={imageFaq}
            alt="imagem secao faq"
            className="w-[400px] md:w-[600px] h-[400px] md:h-[600px]"
          />
        </motion.div>
        <motion.div
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="list"
        >
          {list.map((item, key) => (
            <Accordion datas={item} key={key} />
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default Section4;
