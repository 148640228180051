import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../animation";

import { FaCar } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BsFillBuildingsFill } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";

// COMPONENTES

function Section2() {
  const mensagem = encodeURIComponent(
    "Olá, Conheci a Empresa pelo site e gostaria de obter mais informações."
  );

  return (
    <div id="section2" className="font-[Poppins] bg-green-50 p-4">
      <h1 className="text-center m-auto text-3xl text-[#292929] font-semibold">
        Nossos Serviços
      </h1>
      <p className="text-2xl text-center font-semibold mb-6 p-3 ">
        Oferecemos uma ampla gama de serviços para atender às necessidades dos
        nossos clientes. Veja algumas áreas que o seguro prestacional atua:
      </p>

      <motion.div
        variants={fadeIn("down", 0.5)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="flex flex-col md:flex-row justify-center items-center"
      >
        <div className="border m-2 h-[250px] w-[300px] md:w-[20%] shadow-lg bg-white">
          <div className="flex items-center justify-center border h-[100px] bg-[#70C247]">
            <FaCar className="text-[80px] text-gray-100  " />
          </div>
          <div className="p-2">
            <p className="text-center mt-8 text-xl">Veicular</p>
          </div>
        </div>
        <div className="border m-2 h-[250px] w-[300px] md:w-[20%] shadow-lg bg-white ">
          <div className="flex items-center justify-center border h-[100px] bg-[#70C247]">
            <MdOutlineAttachMoney className="text-[80px] text-gray-100 " />
          </div>
          <div className="p-2">
            <p className="text-center mt-8 text-xl">Empréstimos Consignados</p>
          </div>
        </div>
        <div className="border  m-2 h-[250px] w-[300px] md:w-[20%] shadow-lg bg-white">
          <div className="flex items-center justify-center border h-[100px] bg-[#70C247]">
            <MdOutlineAttachMoney className="text-[80px] text-gray-100 " />
          </div>
          <div className="p-2">
            <p className="text-center mt-8 text-xl">Empréstimos Pessoal</p>
          </div>
        </div>
        <div className="border  m-2 h-[250px] w-[300px] md:w-[20%] shadow-lg bg-white">
          <div className="flex items-center justify-center border h-[100px] bg-[#70C247]">
            <BsFillBuildingsFill className="text-[80px] text-gray-100 " />
          </div>
          <div className="p-2">
            <p className="text-center mt-8 text-xl">Empréstimos Empresariais</p>
          </div>
        </div>
      </motion.div>
      <a href={`https://wa.me/55062995528649?text=${mensagem}`} target="blank">
        <button className="flex w-full h-full max-w-[350px] m-auto mt-6 mb-6  items-center justify-center border p-3 bg-green-500 text-white font-semibold text-lg rounded-3xl cursor-pointer">
          <FaWhatsapp className="mr-2 text-2xl" />
          Fale com um Especialista
        </button>
      </a>
    </div>
  );
}

export default Section2;
