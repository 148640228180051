import React, { useEffect, useState } from "react";

import estrela from "../assets/estrela.png";

const Comments = [
  {
    id: 1,
    nome: "Harley Moraes",
    image: "/imagem-harley.png",
    comment:
      "Muito bom parabéns pelo trabalho. Minha esposa recebeu tudo o que era de direito dela.",
  },
  {
    id: 2,
    nome: "ana maria",
    image: "/imagem-ana.png",
    comment:
      "Me ajudaram na hora que eu estava precisando, não sabia que tinha direito ao seguro que me devolveram.",
  },
  {
    id: 3,
    nome: "Lu",
    image: "/imagem-lu.png",
    comment: "Excelente serviço!",
  },
  {
    id: 4,
    nome: "Gabriel Pereira",
    image: "/imagem-gabriel.png",
    comment: "Ótimo serviço e atendimento, indico de olhos fechados!",
  },
];

const Testimonials = () => {
  const [comentarios, setComentarios] = useState([]);

  useEffect(() => {
    setComentarios(Comments);
  }, []);

  return (
    <div className="flex flex-col sm:flex-row w-[90%] items-center justify-center m-auto">
      {comentarios[0] ? (
        comentarios.map((comentario) => (
          <ul
            key={comentario.id}
            className="border shadow-xl rounded-xl h-[200px] px-4 py-2 gap-2 mt-6 sm:mt-0 w-[90%] sm:w-[25%] mr-2"
          >
            <div className="flex items-center">
              <img key={comentario.id} src={comentario.image} alt="imagem" />
              <li className="mt-2 ml-2">{comentario.nome}</li>
            </div>
            <div className="mt-4 flex">
              <img className="w-[12px] mr-1" src={estrela} alt="estrela" />
              <img className="w-[12px] mr-1" src={estrela} alt="estrela" />
              <img className="w-[12px] mr-1" src={estrela} alt="estrela" />
              <img className="w-[12px] mr-1" src={estrela} alt="estrela" />
              <img className="w-[12px]" src={estrela} alt="estrela" />
            </div>
            <li className="text-sm mt-1">{comentario.comment}</li>
          </ul>
        ))
      ) : (
        <p>Carregando...</p>
      )}
    </div>
  );
};

export default Testimonials;
